import './App.css';
import MainComponent from './components/MainComponent';
import NavComponent from './components/NavComponent';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NobetciComponent from './components/NobetciComponent';
import NoPage from './components/NoPage';
import Kahramanlar from './components/Kahramanlar';
import Ders from './components/Ders';
import Haritalar from './components/Haritalar';
import Kitaplar from './components/Kitaplar';
import { PrimeReactProvider } from 'primereact/api';


//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import HasanOnbası from './components/HasanOnbası';
        

function App() {
  return (
    <PrimeReactProvider><div className="App">
      <BrowserRouter>
        <NavComponent />
        <Routes>
          <Route
            path="/"
            element={(<MainComponent />)}
          />
          <Route
            path="/hakkımızda"
            element={(<MainComponent />)}
          />
          <Route
            path="/kahramanlar"
            element={(<Kahramanlar />)}
          />
          <Route
            path="/hasanonbası"
            element={(<HasanOnbası />)}
          />
          <Route
            path="/videolar"
            element={(<Ders />)}
          />
          <Route
            path="/haritalar"
            element={(<Haritalar />)}
          />
          <Route
            path="/arastırma"
            element={(<Kitaplar />)}
          />
          <Route
            path='/nobetci/:name'
            element={<NobetciComponent />}
          />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div></PrimeReactProvider>

  );
}

export default App;
