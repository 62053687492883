import React, { useRef, useEffect } from 'react'
import { useParams } from "react-router-dom";
import exportAsImage from "./ExportAsImage";
import { Tabs } from 'antd';
import story from "./story.png"
import post from "./post.png"
import "../styles/Nobetci.css"
import { message } from 'antd';
import background1 from "./nav4.png";

function NobetciComponent() {

    const { name } = useParams();
    const exportRef = useRef();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        messageApi.open({
            type: 'success',
            content: 'Tebrikler, Kudüs Nöbetçisi Oldunuz! Sertifikanızı İndirmeyi Unutmayın!',
            className: 'custom-class',
            style: {
                marginTop: '20vh',
                fontFamily: "Source Sans Variable",
                fontSize: "120%"
            },

        });
    }, []);

    return (
        <div  className='container' >
            <div style={{ backgroundImage: `url(${background1})` }} className='row row11 g-0'>
                <div className="col-sm-3">
                </div>
                <div className="col-sm-6">
                <br></br>
                {contextHolder}
                <button onClick={() => {
                    exportAsImage(exportRef.current, "kudusnobetcileri");
                }} style={{ borderRadius: "20px" }} class="btn m-2 btn-success" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg></button>
                <a style={{ borderRadius: "20px" }} href="https://twitter.com/intent/tweet?text=Ben%20de%20Kudüs%20Nöbetçisiyim&hashtags=kudusnobetcileri" class="btn btn-dark" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                </svg></a>
                    <Tabs
                        defaultActiveKey="1"
                        centered
                        size='large'
                        items={[{
                            label: `Post`,
                            itemColor: 'rgba(25, 7, 119, 0.9)',
                            itemHoverColor: "rgba(255, 255, 255, 0)",
                            key: 1,
                            children: <div className="row border overflow-hidden m-1">
                                <div className="col-12 p-2 d-lg-block">
                                    <div ref={exportRef} id="my-img" className="DisplayImg">
                                        <img
                                            src={post}
                                            className="img-fluid img-thumbnail"
                                            alt=""
                                            width="100%"
                                        />
                                        <h2 className="topText">{"Sayın: " + name.toLocaleUpperCase()}</h2>
                                    </div>
                                </div>
                            </div>
                        },
                        {
                            label: `Hikaye`,
                            key: 2,
                            children: <div className="row border overflow-hidden m-1">
                                <div className="col-12 p-2 d-lg-block">
                                    {/* This id is defined for downloading images */}
                                    <div ref={exportRef} id="my-img" className="DisplayImg1">
                                        <img
                                            src={story}
                                            className="img-fluid img-thumbnail"
                                            alt=""
                                            width="100%"
                                        />
                                        <h2 className="topText">{"Sayın: " + name.toUpperCase()}</h2>
                                    </div>
                                </div>
                            </div>
                        }]}
                    />
                </div>
                <div className="col-sm-3" />
            </div>
        </div>
    )
}

export default NobetciComponent