import React, { useState, useEffect } from 'react'
import "../styles/Main.css"
import bg from "./bg5.png"
import photo from "./34.png"
import { BallTriangle } from 'react-loader-spinner'
import { Progress, Statistic, Space } from 'antd';
import { useNavigate } from "react-router-dom";
import CountUp from 'react-countup';
import axios from "axios";

function MainComponent() {
    const formatter = (value) => <CountUp end={value} separator="." />;
    const [hedef, setHedef] = useState(1000000);
    const [gerceklesen, setGerceklesen] = useState(10000);
    const [name, setName] = useState("");
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setSpinner(true);
        axios.get(process.env.REACT_APP_API_URL + "data").then((response) => {
            setHedef(response.data.hedef);
            setGerceklesen(response.data.gerceklesen);
            setSpinner(false);
        })
            .catch(error => {
                setSpinner(false);
                console.log(error)
            })
    }, []);

    const postData = () => {
        setSpinner(true);
        axios
            .put(process.env.REACT_APP_API_URL + "data", {
                name: name,
            })
            .then((response) => {
                console.log(response.data);
                setSpinner(false);
                navigate("/nobetci/" + name);
            })
            .catch((error) => {
                console.log(error);
                setSpinner(false);
                navigate("/nobetci/" + name);
            })

    }

    const handleOnChange = (event) => {
        setName(event.target.value)
    }

    return (
        <div className='container' style={{ marginTop: "0%" }}>
            {spinner ? (<div className='row'>
                <div className='col-5'></div>
                <div style={{ marginTop: "15%", marginLeft: "5%" }} className='col-2'><BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                /></div>
                <div className='col-5'></div>
            </div>) : (<div className='row row11 g-0' style={{ backgroundImage: `url(${bg})`, backgroundPosition: "top", backgroundRepeat: "repeat" }}>
                <div className='col-sm-3'></div>
                <div className='col-sm-6'>
                    <div style={{paddingTop: "6%"}}  class="justify-content-center">
                        <img src={photo} style={{ width: "60%" }} />
                        <br></br>
                        <br></br>
                        <br></br>
                        <input style={{ borderRadius: "30px" }} type="text" class="form-control mb-2" placeholder="Ad Soyad" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={handleOnChange}></input>
                        {name.length > 0 ? (<button onClick={postData} class="btn btn-danger" role="button" style={{ borderRadius: "30px" }} id="button-addon2">Kudüs Nöbetçisi Ol</button>) : (<button disabled class="btn btn-danger" type="button" style={{ borderRadius: "30px" }} id="button-addon2">Kudüs Nöbetçisi Ol</button>)}
                    </div>
                    <br></br>
                    <br></br>
                    <Space style={{ marginLeft: "5%", marginRight: "5%" }} size={'large'}>
                        <Statistic valueStyle={{ fontSize: "20px", color: "rgb(255, 254, 255)" }} title="Katılan Nöbetçi" value={gerceklesen} formatter={formatter} />
                        <Progress type="circle" percent={100 * gerceklesen / hedef} strokeColor="rgb(0, 193, 0)" trailColor="rgb(208, 221, 229)" format={(percent) => (<a style={{ color: "rgb(208, 221, 229)" }}>%{percent}</a>)} />
                        <Statistic valueStyle={{ fontSize: "20px", color: "rgb(255, 254, 255)" }} title="Hedef Nöbetçi" value={hedef} formatter={formatter} />
                    </Space>
                    <br></br>
                    <div style={{ padding: "5%" }} class="container">
                        <img src={`_${Math.floor(10 * gerceklesen / hedef) * 10}.png`} style={{ width: "100%" }} />
                    </div>
                </div>
                <div className='col-sm-3 col3'></div>
            </div>)}
        </div>
    )
}

export default MainComponent
