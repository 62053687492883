import data from "../Data/haritalar.json"
import haritalar from "./haritalar.png";
import React from 'react'
import "../styles/Videolar.css"
import background1 from "./nav4.png";

function Haritalar() {
    return (
        <div className='row g-0' style={{ backgroundImage: `url(${background1})`, padding: "50px" }}>
            {data.map((item, index) => (
                <div class="col-lg-6 mr-2 mt-2" style={{ paddingRight: "10px" }}>
                    <div class="hover hover-1 text-white rounded"><img src={`${item.src}.png`}  alt="" />
                        <div class="hover-overlay"></div>
                        <div class="hover-1-content px-5 py-4">
                            <h3 class="hover-1-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">{item.title}</span></h3>
                            <a href={item.desc} class="hover-1-description font-weight-light mb-0">Haritayı incelemek için <u>tıklayınız...</u></a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Haritalar