import React, { useState } from 'react'
import { Card } from 'primereact/card';
import "../styles/Onbası.css"
import bg from "./bg.jpg";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Modal } from 'antd';

function HasanOnbası() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const showModal = (name) => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className='row g-0' style={{ backgroundColor: "rgb(0, 36, 70)", paddingLeft: "100px", paddingBottom: "100px", paddingTop: "70px", paddingRight: "50px" }}>
            <div class="col-12">
                <div onClick={showModal} class="hover hover-1 text-white rounded"><img width={"50%"} src={`Onbasi.jpg`} alt="" />
                    <div class="hover-overlay"></div>
                    <div class="hover-1-content px-5 py-4">
                        <h3 class="hover-1-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">Iğdırlı Hasan Onbaşı</span></h3>
                        <p class="hover-1-description font-weight-light mb-0">Detaylı bilgi için tıklayınız...</p>
                    </div>
                </div>
            </div>

            <Modal title={selected.isim} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <ScrollPanel style={{ height: '400px' }} className="custombar1">
                <p className="m-0" style={{ textAlign: "justify" }}>
                Filistinli âlim Şeyh İkrime Sabri, 1982 yılındaki vefatına kadar Kudüs'ü terk
                etmeyerek Mescid-i Aksa'daki nöbetini sürdüren Osmanlı askeri Iğdırlı Onbaşı Hasan ile
                ilgili hatıralarını anlattı. Osmanlı ordusu çekilirken yaşanabilecek yağmalamaların önüne geçmek için Kudüs'te
                bırakılan 53 Osmanlı askerinden biri olan Onbaşı Hasan'ın hikâyesi işgal altındaki Kudüs'te
                yaşayan ihtiyarlar arasında dilden dile dolaşıyor.
                Kudüs Yüksek İslami Heyeti Başkanı ve Mescid-i Aksa Hatibi Şeyh Sabri, Aksa'daki son
                Osmanlı askeri olarak bilinen Onbaşı Hasan'ı görme fırsatı bulan bu neslin son
                temsilcilerinden biri.
                Zeytin Dağı'ndaki evinde AA muhabirine konuşan 80 yaşındaki Şeyh Sabri, "Onbaşı Hasan'ın
                kalbi Mescid-i Aksa ile atıyordu. 1982'deki vefatına kadar sabah mescide ilk giren o olur,
                yatsı namazından sonra da yine mescitten en son o ayrılırdı." dedi.
                Şeyh Sabri, şehirdeki bu son Osmanlı askerinin her gün Harem-i Şerif'in kuzeyinden
                Kubbetu's Sahra'ya doğru çıkan merdivenlerin başına gelerek sabahtan akşama kadar buradan
                ayrılmadığını, adeta nöbet tutarcasına aynı noktada beklediğini anlattı.
                Iğdırlı Onbaşı Hasan'ın cemaatle konuştuğuna pek tanık olmadığını dile getiren Şeyh Sabri,
                bunun sebebinin muhtemelen Arapçayı çok iyi bilmemesi olduğunu söyledi.
                "Vefatına kadar Mescid-i Aksa'daki nöbetine devam etti"
                Osmanlı Devleti'nin Birinci Dünya Savaşını kaybetmesinin akabinde Kudüs'ün başına
                gelenlerden büyük bir üzüntü duyduğu anlaşılan Onbaşı Hasan için "Mahzun ve kendi halinde
                bir görünümü vardı. Vefatına kadar Mescid-i Aksa'daki nöbetine devam etti." ifadelerini
                kullanan Filistinli âlim Sabri, şunları kaydetti:
                "Kimseyle pek muhatap olmak istemeyen kendi halinde bir görünümü vardı. Osmanlı
                Devleti'nin Birinci Dünya Savaşını kaybetmesinden ve akabinde Kudüs'ün başına gelenlerden
                büyük bir üzüntü duyduğu her halinden anlaşılıyordu. Son derece mütevazı, hatta yamalı bir
                kıyafet giyerdi."
                Şeyh Sabri, 1982'de hayatını kaybeden Onbaşı Hasan'ın kabrinin nerede olduğunu ise
                bilmediğini söyledi.
                </p>

                </ScrollPanel>
            </Modal>
        </div>
    )
}

export default HasanOnbası