import React, { useState } from 'react'
import data from "../Data/ders-videoları.json"
import { Modal } from 'antd';
import "../styles/Videolar.css"
import background1 from "./nav4.png";

function Ders() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const showModal = (name) => {
        setSelected(name);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className='row g-0' style={{ backgroundImage: `url(${background1})`, padding: "50px" }}>
            {data.map((item, index) => (
                <div class="col-lg-4 mr-2 mt-2" style={{paddingRight: "5px"}}>
                    <div onClick={() => showModal(item)} class="hover hover-1 text-white rounded"><img src={"aksa.png"} alt="" />
                        <div class="hover-overlay"></div>
                        <div class="hover-1-content px-5 py-4">
                            <h3 class="hover-1-title text-uppercase font-weight-bold mb-0"> <span style={{fontSize: "14px"}} class="font-weight-light">{item.title}</span></h3>
                            <p class="hover-1-description font-weight-light mb-0">Videoyu izlemek için tıklayınız...</p>
                        </div>
                    </div>
                </div>
            ))}
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <iframe width={"100%"}
                    src={selected.desc}>
                </iframe>
            </Modal>
        </div>
    )

}

export default Ders