import React, { useState } from 'react'
import data from "../Data/kahramanlar.json"
import { Modal } from 'antd';
import "../styles/style_img.css"
import { ScrollPanel } from 'primereact/scrollpanel';
import background1 from "./nav4.png";

function Kahramanlar() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState({});
    const showModal = (name) => {
        setSelected(name);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className='row g-0' style={{ backgroundImage: `url(${background1})`, paddingLeft: "50px", paddingBottom: "70px", paddingTop: "70px", paddingRight: "50px"}}>
            {data.map((item, index) => (
                <div class="col-lg-4">
                    <div onClick={() => showModal(item)} class="hover hover-1 text-white rounded"><img src={`${item.src}.jpeg`} alt="" />
                        <div class="hover-overlay"></div>
                        <div class="hover-1-content px-5 py-4">
                            <h3 class="hover-1-title text-uppercase font-weight-bold mb-0"> <span class="font-weight-light">{item.isim}</span></h3>
                            <p class="hover-1-description font-weight-light mb-0">Detaylı bilgi için tıklayınız...</p>
                        </div>
                    </div>
                </div>
            ))}
            <Modal title={selected.isim} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <ScrollPanel style={{ height: '400px' }} className="custombar1">
                    <p style={{textAlign: "justify"}}>
                        {selected.metin}
                    </p>
                </ScrollPanel>
            </Modal>
        </div>
    )
}

export default Kahramanlar