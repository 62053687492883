import React from 'react';
import Flag from 'react-flagkit';
import "../styles/Navbar.css";
import background1 from "./nav4.png";
import tugva from "./tugva.png";
import Nav from 'react-bootstrap/Nav';

function NavComponent() {

    return (
        <div className='container-fluid g-0'>
            <div className='row row1 g-0' style={{ backgroundImage: `url(${background1})` }}>
                <div className='col-3 col1'>
                    <Flag className='flag' size={50} style={{ marginRight: "4px" }} country="TR" />
                    <Flag className='flag' size={50} country="PS" />
                </div>
                <div className='col-6 text-center col2'><span><b>Kudüs'ün Son Nöbetçisi Iğdırlı Hasan Onbaşı Anısına...</b></span></div>
                <div className='col-3 col3'>
                    <img
                        width={100}
                        className='img11'
                        src={tugva}
                        alt="React Bootstrap logo"
                    />
                </div>
            </div>
            <div className='row row2 g-0'>
                        <Nav className='justify-content-center' >
                            <Nav.Item>
                                <Nav.Link style={{color: "rgb(31, 36, 39)"}} href="/">Ana Sayfa</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{color: "rgb(31, 36, 39)"}} href="/hakkımızda">Hakkımızda</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{color: "rgb(31, 36, 39)"}} href="/kahramanlar">Kudüs Kahramanları</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{color: "rgb(31, 36, 39)"}} href="/videolar">Tematik Videolar</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{color: "rgb(31, 36, 39)"}} href="/haritalar">Haritalar</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link style={{color: "rgb(31, 36, 39)"}} href="/arastırma">Kitaplık</Nav.Link>
                            </Nav.Item>
                        </Nav>
            </div>
        </div>
    )
}

export default NavComponent