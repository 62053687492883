import React from 'react'
import data from "../Data/kitaplik.json"
import { Divider, List } from 'antd';
import background1 from "./nav4.png";
import "mdb-ui-kit/css/mdb.min.css";
import "../styles/Kitaplar.css"

function Kitaplar() {
    return (
        <div style={{ backgroundImage: `url(${background1})`, backgroundPosition: "top", backgroundRepeat: "repeat", padding: "2%" }}>
            <div className='row'>
            <div className='col-md-3'></div>
            <div class="list-group list-group-light col-md-6">
                {data.map((item, index) => (
                    <span class="change list-group-item px-3 mt-2 border-0 rounded-3" style={{backgroundColor: "rgb(174, 185, 185)"}}>
                        {item.isim}</span>
                ))}
            </div>
            <div className='col-md-3'></div>
            </div>

        </div>
    )
}

export default Kitaplar